<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1">
      {{ $t("af:application.sign.title") }}
    </p>
    <p class="mb-5">{{ $t("af:application.sign.subtitle") }}</p>

    <div class="is-flex is-align-items-center mb-4">
      <b-icon
        icon-pack="fa"
        icon="check-circle"
        type="is-green"
        class="mr-2"
      ></b-icon>

      <span class="has-text-weight-medium has-text-green">{{
        $t("af:application.sign.label.operator_available")
      }}</span>
    </div>

    <p class="mb-5">{{ $t("af:application.video_call.title") }}</p>

    <div v-if="loaded" class="kyc-types">
      <div
        class="kyc-type is-clickable kyc-type mb-5"
        v-for="(typ, index) in configs"
        :id="`kycChooseType_${typ}`"
        v-bind:key="index"
        @click="selectedType = typ.id"
      >
        <div class="is-flex">
          <b-icon
            v-if="typ.icon"
            icon-pack="fa"
            :icon="typ.icon"
            type="is-grey-icon"
            size="is-large"
            class="mr-4"
          ></b-icon>

          <div
            class="is-flex is-flex-direction-column is-justify-content-center"
          >
            <span class="has-text-weight-bold">{{ typ.label }}</span>
            <span class="is-size-7 has-text-grey-icon">{{
              typ.description
            }}</span>
          </div>

          <b-radio
            type="is-blue"
            size="is-large"
            name="selectedType"
            class="ml-auto"
            v-model="selectedType"
            :native-value="typ.id"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p class="mb-5">{{ $t("af:application.kyc_config.loading") }}</p>
    </div>

    <div class="devices">
      <div class="device">
        <div id="ButtonSignOnMobile" class="device__tile" @click="goMobile">
          <b-icon
            icon-pack="fa"
            icon="mobile"
            type="is-grey-icon"
            size="is-large"
          ></b-icon>

          <p class="has-text-centered has-text-weight-bold mb-0">
            {{ $t("af:application.video_call.label.mobil") }}
          </p>

          <p class="has-text-centered is-size-7">
            {{ $t("af:application.video_call.label.mobil.footer") }}
          </p>
        </div>
      </div>
      <div class="device">
        <div id="ButtonSignOnDesktop" class="device__tile" @click="goDesktop">
          <b-icon
            icon-pack="fa"
            icon="desktop"
            type="is-grey-icon"
            size="is-large"
          ></b-icon>

          <p class="has-text-centered has-text-weight-bold mb-0">
            {{ $t("af:application.video_call.label.this_device") }}
          </p>

          <p class="has-text-centered is-size-7">
            {{ $t("af:application.video_call.label.this_device.footer") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  name: "ApplicationFlowKYCChoose",
  props: {
    position: {
      type: String,
      required: true,
    },
    sigTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedType: null,
      configs: [],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      getConfig: "kycConfig/getConfig",
      url: "loanApplication/kycUrl",
    }),
  },
  methods: {
    ...mapActions({
      savePosition: "loanApplication/savePosition",
      fetchConfigs: "kycConfig/fetchConfigs",
    }),
    async goMobile() {
      await this.$store.dispatch("loanApplication/setSignMobile", true);
      let selectedType;
      if (this.selectedType) {
        selectedType = this.getConfig(this.selectedType).identifier;
      } else {
        selectedType = "DEFERRED";
      }
      try {
        await this.$store.dispatch("loanApplication/startSign", selectedType);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:signature.start_failed"),
          position: "is-bottom",
          type: "is-danger",
        });
      }
      this.track("signature_started");
    },
    async goDesktop() {
      await this.$store.dispatch("loanApplication/setSignMobile", false);
      let selectedType;
      if (this.selectedType) {
        selectedType = this.getConfig(this.selectedType).identifier;
      } else {
        selectedType = "DEFERRED";
      }
      await this.$store.dispatch("loanApplication/startSign", selectedType);
      if (this.url) {
        window.location.href = this.url;
      }
      this.track("signature_started");
    },
    track(eventName) {
      this.$gtag.event(eventName);
    },
  },
  async mounted() {
    await this.fetchConfigs();
    for (let t in this.sigTypes) {
      this.configs.push(this.getConfig(this.sigTypes[t]));
    }
    this.selectedType = this.configs[0]?.id;
    this.loaded = true;
  },
};
</script>

<style scoped lang="scss">
.kyc-type {
  border: 1px solid #dfe6ef;
  border-radius: 16px;
  padding: 24px;
  cursor: pointer;
}

.devices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: start;

    &__tile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 32px;
      padding: 24px;
      cursor: pointer;
      margin-bottom: 1rem;
      gap: 16px;

      .icon {
        font-size: 0.75rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .devices {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
